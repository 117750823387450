import FeatureComponent from './FeatureComponent';

const ExtraOptions = ({ totalPrice, setTotalPrice, selectedFeatures, setSelectedFeatures }) => {

    const handleFeatureToggle = (feature) => {
        setSelectedFeatures(prevFeatures => {
            const isFeatureSelected = !prevFeatures[feature];
            const newFeatures = { ...prevFeatures, [feature]: isFeatureSelected };

            let newTotalPrice = isFeatureSelected 
            ? totalPrice + featurePrices[feature]
            : totalPrice - featurePrices[feature];

            setTotalPrice(newTotalPrice);

            return newFeatures;
        });
    };

    // Feature prices mapping
    const featurePrices = {
        "Assembly for 2.45 x 5.95 Studio": 3000,
        "Sound Proofing Internal Cladding": 1600,
        "Internal Composite Cladding": 1600,
        "Double Glazed Window Upgrade": 300,
        "600mm Kitchenette": 400,
        "1200mm Kitchenette": 650,
        "2m Glass Floor to Ceiling Double Glazed Glass Curtain Wall": 1800,
        "Starlink Setup": 1000,
        "Exhaust Fan": 50,
        "Decking for 2.45 x 5.95 (1 Side Only)": 1400,
        "Veranda": 1500,
    };

    return (
        <div>
            <h1>{totalPrice} AUD</h1>

            <FeatureComponent feature={"Assembly for 2.45 x 5.95 Studio"} price={3000} visible={selectedFeatures["Assembly for 2.45 x 5.95 Studio"]} onFeatureToggle={() => handleFeatureToggle("Assembly for 2.45 x 5.95 Studio", 3000)} />
            <FeatureComponent feature={"Sound Proof Internal Cladding"} price={1600} visible={selectedFeatures["Sound Proofing Internal Cladding"]} onFeatureToggle={() => handleFeatureToggle("Sound Proofing Internal Cladding", 1600)} />
            <FeatureComponent feature={"Internal Composite Cladding"} price={1600} visible={selectedFeatures["Internal Composite Cladding"]} onFeatureToggle={() => handleFeatureToggle("Internal Composite Cladding", 1600)} />
            <FeatureComponent feature={"Double Glazed Window"} price={300} visible={selectedFeatures["Double Glazed Window Upgrade"]} onFeatureToggle={() => handleFeatureToggle("Double Glazed Window Upgrade", 300)} />
            <FeatureComponent feature={"600mm Kitchenette"} price={400} visible={selectedFeatures["600mm Kitchenette"]} onFeatureToggle={() => handleFeatureToggle("600mm Kitchenette", 400)} />
            <FeatureComponent feature={"1200mm Kitchenette"} price={650} visible={selectedFeatures["1200mm Kitchenette"]} onFeatureToggle={() => handleFeatureToggle("1200mm Kitchenette", 650)} />
            <FeatureComponent feature={"Glass Floor to Ceiling Double Glazed Wall"} price={1800} visible={selectedFeatures["2m Glass Floor to Ceiling Double Glazed Glass Curtain Wall"]} onFeatureToggle={() => handleFeatureToggle("2m Glass Floor to Ceiling Double Glazed Glass Curtain Wall", 1800)} />
            <FeatureComponent feature={"Starlink Setup (Where available)"} price={1000} visible={selectedFeatures["Starlink Setup"]} onFeatureToggle={() => handleFeatureToggle("Starlink Setup", 1000)} />
            <FeatureComponent feature={"Exhaust Fan"} price={50} visible={selectedFeatures["Exhaust Fan"]} onFeatureToggle={() => handleFeatureToggle("Exhaust Fan", 50)} />
            <FeatureComponent feature={"Decking for 2.45 x 5.95 (1 Side Only)"} price={1400} visible={selectedFeatures["Decking for 2.45 x 5.95 (1 Side Only)"]} onFeatureToggle={() => handleFeatureToggle("Decking for 2.45 x 5.95 (1 Side Only)", 1400)} />
            <FeatureComponent feature={"Veranda"} price={1500} visible={selectedFeatures["Veranda"]} onFeatureToggle={() => handleFeatureToggle("Veranda", 1500)} />
        </div>
    );
};

export default ExtraOptions;
